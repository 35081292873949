import React, { useEffect } from "react";
import get from "lodash/get";
import { connect, useDispatch } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { JsonLd } from "react-schemaorg";
import { withI18n } from "@lingui/react";
import * as types from "../../stores/types";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import { sendListViewedEvent } from "../../functions/analytics";
import { fetchLinkings, fetchOverview } from "../../functions/fetchData";
import { Api } from "../../functions/fetchFromApi";
import { fetchDefaultCurrency } from "../../functions/currency";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import Root from "../../components/_Root";
import ScrollHook from "../../components/ScrollHook";
import Hero from "../../components/Hero";
import ProductsGrid from "../../components/ProductsGrid";
import Attractions from "../../components/Attractions";
import { getProductLink } from "../../functions/getProductLink";
import getDomainZone from "../../functions/url/getDomainZone";
import Cities from "../../components/Cities";
import "./Country.css";
import { CanonicalAuto } from "../../components/Canonical";
import { AlternateAuto } from "../../components/Alternate";
import LinkingsGroup from "../../components/Linking";
import CookieFooter from "../../components/CookieFooter";
import StackedAccordions from "../../components/StackedAccordions";
import replaceImgTagsInHtml from "../../functions/optimizeImagesInHtml";

const SEOblock = withI18n()(({ i18n, country, cover, path, products, lang }) => {
  const DNSZone = getDomainZone(lang);
  return (
    <JsonLd
      item={{
        "@context": "https://schema.org",
        "@type": "Product",
        url: `https://wegotrip.${DNSZone}${path}`,
        sku: country.id,
        name: country.name,
        description:
          country.meta_description ||
          i18n._(
            t`The best audio guides for ${
              country.name
            } from experts. Download and listen to online audio guides to the sights and places of interest of ${inclineIfNeeded(
              country.name,
              "from",
              i18n.language,
            )}.`,
          ),
        brand: { "@type": "Brand", name: "WeGoTrip" },
        image: cover,
        ...(products.count
          ? {
              offers: products.products.map(product => ({
                "@type": "Offer",
                price: product.price,
                priceCurrency: product.currencyCode,
                url: `https://wegotrip.${DNSZone}${getProductLink(lang, product)}`,
                availability: "https://schema.org/InStock",
              })),
            }
          : {}),
      }}
    />
  );
});

function Country({
  lang,
  cover,
  country = {},
  cities = [],
  products,
  attractions = [],
  destinations,
  path,
  linkings,
  seoHtml,
}) {
  // TODO: refactor

  const countryName = inclineIfNeeded(country.name, "in", lang);
  const productListId = `country_${country.slug}_${country.id}`;

  /**
   * Product list became visible in viewport
   */
  const onProductListShown = () => {
    sendListViewedEvent(productListId, products.products, lang);
  };

  return (
    <Root searchInHeader stickyHeader destinations={destinations}>
      <AlternateAuto route="country" />
      <CanonicalAuto route="country" />
      <div className="Country-page">
        <Hero
          current="country"
          metaDescription={country.meta_description}
          metaTitle={country.meta_title}
          country={country}
          cover={cover}
        />
        <div className="Wrapper">
          {cities.length > 0 && (
            <div className="m-48">
              <Cities
                cities={cities}
                title={<Trans>Best cities to visit in {country.name}</Trans>}
              />
            </div>
          )}
          {attractions.length ? <Attractions attractions={attractions} /> : null}

          <div className="Country-page__section">
            <h2 className="h1 m-32 m-64-t Country-page__title">
              <Trans>All tours and tickets in {countryName}</Trans>
            </h2>
            <ScrollHook once="shown" showOn=".Country__products" onChanged={onProductListShown} />
            <ProductsGrid
              {...products}
              className="Country__products"
              listId={productListId}
              selectParams={{ countryId: country.id }}
            />
          </div>
          <StackedAccordions data={country.faqs} TitleTag="h2">
            <Trans>About {country.name}</Trans>
          </StackedAccordions>
          {lang === "en" && linkings && (
            <LinkingsGroup
              linkings={{
                attractions_world: linkings.attractions_world,
                attractions_country: linkings.attractions_country,
              }}
              lang={lang}
              countryName={country.name}
            />
          )}
          {country?.description ? (
            <div
              className="Country-page__seo m-48"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: seoHtml,
              }}
            />
          ) : null}
          {/* TODO: SEO text */}
          <CookieFooter />
        </div>
        <SEOblock country={country} cover={cover} path={path} products={products} lang={lang} />
      </div>
    </Root>
  );
}

Country.getInitialProps = withRedirectToKnownLang(async ({ req, match, store }) => {
  const { countryId, countrySlug } = match.params;
  const lang = getCurrentLanguage(match.params.lang);

  const currency = await fetchDefaultCurrency(req);
  store.dispatch({ type: types.SET_DEFAULT_CURRENCY, defaultCurrency: currency });

  const cookies = get(req, "headers.cookie");
  store.dispatch({ type: types.FETCH_USER, cookies });
  store.dispatch({ type: types.FETCH_PRODUCTS, countryId, lang });

  // TODO: handle 404 and empty countries
  // find a generic way to handle network errors
  const overview = await fetchOverview({ lang }, req);
  store.dispatch({ type: types.SET_OVERVIEW, data: overview });

  let country;
  try {
    country = (
      await Api.get(
        `/api/v2/countries/${countryId}/?expand=meta_title,meta_description,description&lang=${lang}&preorder=true`,
        { lang },
      )
    ).data;
  } catch (e) {
    if (!country) {
      return { statusCode: 404 };
    }
  }

  const cities = (
    await Api.get(`/api/v2/cities/?country=${countryId}&lang=${lang}&preorder=true`, {
      lang,
    })
  ).data.results;

  const attractions = (
    await Api.get(`/api/v2/attractions/?country=${countryId}&lang=${lang}&preorder=true`, {
      lang,
    })
  ).data.results;

  let cover = country.preview_image;

  if (!country.preview_image && cities.length) {
    cover = (await Api.get(`/api/v2/cities/${cities[0].id}/?preorder=true`)).data.cover;
  }

  let linkings = {};
  if (cities.length > 0 && cities[0] && cities[0].id) {
    linkings = await fetchLinkings({ lang, id: cities[0].id });
  }

  const seoHtml = replaceImgTagsInHtml(country.description) || "";

  const result = {
    lang,
    cover,
    country: { ...country, slug: countrySlug },
    cities,
    attractions,
    destinations: overview.topCities,
    path: req?.originalUrl,
    linkings,
    seoHtml,
  };

  return result;
});

const mapStateToProps = ({ products }) => ({
  products,
});

export default connect(mapStateToProps)(Country);
