import posthtml from "posthtml";
import { getImgProxyUrl } from "../constants/imgproxy";

export function replaceImgTagsWithImgProxyPlugin(tree) {
  tree.match({ tag: "img" }, node => {
    try {
      const {
        attrs: { style, src },
      } = node;
      const width = parseInt(style.match(/width:([\d]+)px/)[1], 10);
      const height = parseInt(style.match(/height:([\d]+)px/)[1], 10);
      return Object.assign(node, {
        //   TODO: generate src-set
        attrs: { loading: "lazy", src: getImgProxyUrl(src, { width, height }) },
      });
    } catch (error) {
      console.log(error);
    }
    return node;
  });
}

export default function replaceImgTagsInHtml(htmlString) {
  return posthtml().use(replaceImgTagsWithImgProxyPlugin).process(htmlString, { sync: true }).html;
}
