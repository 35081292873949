import React from "react";
import InputLabel from "../InputLabel";

/**
 * Adds label to `withWrapper(Input)` component
 * @param {React::Component} Input - input with wrapper to overload
 */
export default function withLabel(Input) {
  /**
   * `Input` component with label
   * @param {Object} $
   * @param {JSX} $.label - label text
   * @param {Object} $.inputProps - will be passed to original `Input` component
   */
  return function LabelledInput({
    label,
    labelTheme,
    className,
    WrapperTag = "div",
    ...inputProps
  }) {
    return (
      <WrapperTag className={className} style={{ position: "relative" }}>
        <InputLabel
          margin={!labelTheme}
          theme={labelTheme}
          disabled={inputProps.disabled}
          error={inputProps.error}
        >
          {label}
        </InputLabel>
        <Input {...inputProps} />
      </WrapperTag>
    );
  };
}
