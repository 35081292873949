import React from "react";
import classNames from "classnames";
import { Icon } from "../Icon";
import "./ChevronIcon.css";

const ChevronIcon = ({ isOpen, size = 24 }) => {
  return (
    <span
      className={classNames("chevron-icon", isOpen ? "chevron-icon--up" : "chevron-icon--down")}
    >
      <Icon name="chevron/down" width={size} height={size} />
    </span>
  );
};

export default ChevronIcon;
