import React from "react";
import isSameDay from "date-fns/isSameDay";
import { DayContent } from "react-day-picker";
import { BOOKING_AVAILABILITY_END_DATE } from "../../constants";
import { DayCaption } from ".";

const getDateFromProps = ({ date: { year, month, day } = {} }) =>
  year && month && day ? new Date(year, month - 1, day) : null;

export const getFirstAvailableDate = days =>
  days.length ? getDateFromProps(days?.find(({ available }) => available) || {}) : null;

export const getUnavailableDates = (days, today, error) =>
  error
    ? [today, { before: today, after: today }]
    : days
        .filter(({ available }) => !available)
        .map(getDateFromProps)
        .concat({ before: today, after: BOOKING_AVAILABILITY_END_DATE });

export const getDayWithCaption =
  (days = [], currencyCode) =>
  props => {
    const currentDay = days.find(({ date: { year, month, day } }) =>
      isSameDay(new Date(year, month - 1, day), props.date),
    );

    const modifiers = props.activeModifiers;
    return (
      <>
        <DayContent {...props} />
        {!modifiers.selected && !modifiers.outside && (
          <DayCaption {...{ currentDay, currencyCode }} />
        )}
      </>
    );
  };
