/**
 * Combiens `collections` and `categories`.
 * If a `categories` item havs the same name as `colleclion` item only colleclion item stays in array.
 * @param {object[]} collections
 * @param {object[]} categories
 */
export default function (collections, categories) {
  const safeCollections = Array.isArray(collections) ? collections : [];
  const safeCategories = Array.isArray(categories) ? categories : [];

  return safeCategories.reduce((things, category) => {
    if (!safeCollections.some(({ name }) => name === category.name)) things.push(category);
    return things;
  }, safeCollections);
}
