import React, { useRef } from "react";
import classNames from "../../functions/classNames";
import "./Checkbox.css";
import { Icon } from "../Icon";

// CheckboxForFormik should be removed with formik
export function CheckboxForFormik({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue, setFieldTouched } = {}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  withLabel,
  caption,
  placeholder,
  children,
  checkByValue,
  labelClassname,
  type = "checkbox",
  ...props
}) {
  const inputRef = useRef(null);
  const isChecked = checkByValue
    ? field.value
    : inputRef?.current?.checked || props.checked || props.defaultChecked;

  return (
    <label
      className={classNames(
        "CheckboxForFormik",
        isChecked && "CheckboxForFormik--checked",
        labelClassname,
      )}
    >
      <div
        className={classNames(
          "CheckboxForFormik__mark",
          children && "CheckboxForFormik__mark--has-label",
        )}
      >
        <input
          ref={inputRef}
          type={type}
          className="CheckboxForFormik__input"
          placeholder={placeholder}
          {...props}
          {...field}
        />
        <div className="CheckboxForFormik__graphics">
          <Icon name="check/black" width="16" height="16" />
        </div>
      </div>
      <div>{children}</div>
    </label>
  );
}

export default function Checkbox({ children, label, type = "checkbox", ...props }) {
  return (
    <label className="Checkbox">
      <input type={type} className="Checkbox__input" {...props} />
      <Icon className="Checkbox__graphics" name="done" width="20" height="20" />
      {children || label}
    </label>
  );
}
