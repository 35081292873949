/**
 * Create cached state and compare objects as string. Based on сlosures.
 */
export default function memoizedState(initState) {
  let cachedState = initState || {};
  return (newState, callback) => {
    if (JSON.stringify(newState) !== JSON.stringify(cachedState)) {
      callback();
      cachedState = newState;
    }
  };
}
