/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { connect, useDispatch, useSelector } from "react-redux";
import { Trans, Plural } from "@lingui/macro";
import get from "lodash/get";
import { JsonLd } from "react-schemaorg";
import LazyHydrate from "react-lazy-hydration";
import * as types from "../../stores/types";
import { isMobileUserAgent, getUserAgent } from "../../functions/userAgent";
import { Api } from "../../functions/fetchFromApi";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import { fetchDefaultCurrency } from "../../functions/currency";
import toQueryString from "../../functions/toQueryString";
import { sendListViewedEvent } from "../../functions/analytics";
import { fetchLinkings, fetchOverview } from "../../functions/fetchData";
import reverseUrl from "../../functions/reverseUrl";
import replaceImgTagsInHtml from "../../functions/optimizeImagesInHtml";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import Root from "../../components/_Root";
import { isSSR } from "../../components/NoSSR";
import ScrollHook from "../../components/ScrollHook";
import { CanonicalAuto } from "../../components/Canonical";
import Hero from "../../components/Hero";
import ProductsGrid from "../../components/ProductsGrid";
import { TopAttractions } from "../../components/Attractions";
import Cities from "../../components/Cities";
import { CustomerReviews } from "../../components/Reviews";
import { getProductLink } from "../../functions/getProductLink";
import getDomainZone from "../../functions/url/getDomainZone";

import "./City.css";
import { AlternateAuto } from "../../components/Alternate";
import LinkingsGroup from "../../components/Linking";
import ProductsFilter from "../../components/ProductsFilter";
import { preferredLanguage, tourLangCodes, tourLanguages } from "../../functions/urlLanguage";
import CookieFooter from "../../components/CookieFooter";
import { Icon } from "../../components/Icon";
import StackedAccordions from "../../components/StackedAccordions";
// import { OnDemandToursBanner } from "../../components/OnDemandToursBanner";

// TODO: use hooks for redux
function City({
  mobile,
  cities = [],
  city = {},
  countryAttractions,
  categories = [],
  products,
  destinations,
  seoHtml = "",
  path = "",
  reviews = {},
  linkings,
  filters,
}) {
  const dispatch = useDispatch();
  const lang = useCurrentLanguage();
  const DNSZone = getDomainZone(lang);
  const currentPath = isSSR ? path : window.location.pathname;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isDesktop = !isSSR && useMediaQuery("(min-width: 992px)");
  const isMobile = isSSR ? mobile : useMediaQuery("(max-width: 767px)");

  const { count } = useSelector(({ reviews: storeReviews }) => storeReviews);

  const countryName = city.country ? inclineIfNeeded(city.country.name, "in", lang) : "";
  const cityName = city.name ? inclineIfNeeded(city.name, "in", lang) : "";
  const cityNameNearbySubtitle = city.name ? inclineIfNeeded(city.name, "from", lang) : "";
  const productListId = `city_${city.slug}_${city.id}`;

  const countryNameInclined = city.country?.name || "";

  /**
   * Product list became visible in viewport
   */
  const onProductListShown = () => {
    sendListViewedEvent(productListId, products.products, lang);
  };

  return (
    <Root stickyHeader searchInHeader destinations={destinations}>
      <AlternateAuto route="city" />
      <CanonicalAuto route="city" />
      <Hero
        theme="no-background"
        city={city}
        initialMetaTitle={city.meta_title}
        initialMetaDescription={city.meta_description}
        current="city"
      />
      <div className="Wrapper">
        <ProductsFilter
          isMobile={isMobile}
          isDesktop={isDesktop}
          categories={categories}
          dispatch={dispatch}
          city={city}
          lang={lang}
        />
        {/* this block was temporarily removed */}
        {/* <OnDemandToursBanner lang={lang} userId={user.user.id} /> */}
        <LazyHydrate whenVisible>
          <ScrollHook once="shown" showOn=".City__products" onChanged={onProductListShown} />
          <ProductsGrid
            className="City__products"
            listId={productListId}
            selectParams={{
              cityId: city?.id,
              ...filters,
              lang: filters?.lang?.length ? filters.lang : lang,
            }}
            {...products}
          />

          {!products.loading &&
            products.products?.length === products.count &&
            city.products_data?.length > 0 && (
              <>
                <div className="City__nearbyProducts">
                  <div className="City__nearbyProducts_title">
                    <Icon name="search/thin" />
                    <Trans>No more things to do in {cityName}!</Trans>
                  </div>
                  <div className="City__nearbyProducts_subtitle">
                    <Trans>
                      Check out these {city.products_data.length} other activities nearby{" "}
                      {cityNameNearbySubtitle}
                    </Trans>
                  </div>
                </div>
                <ProductsGrid products={city.products_data} />
              </>
            )}
          {city.attractions && city.attractions.some(attraction => attraction.itemsCount > 0) ? (
            <div className="m-64 m-48-xs">
              <h2 className="h1 m-32">
                <Trans>Top attractions in {cityName}</Trans>
              </h2>
              <TopAttractions attractions={city.attractions} />
            </div>
          ) : null}

          {cities.length ? (
            <div className="m-64 m-48-xs">
              <Cities
                slider
                title={<Trans>Popular cities to visit in {countryName}</Trans>}
                cities={cities}
                slidesPerPage={4}
                country={city.country}
              />
            </div>
          ) : null}
          {get(countryAttractions, "results.length") ? (
            <div className="m-64 m-48-xs">
              <h2 className="h1 m-32 m-64-t">
                <Trans>
                  Top attractions in {lang === "ru" ? countryNameInclined : countryName}
                </Trans>
              </h2>
              <TopAttractions attractions={countryAttractions.results} />
            </div>
          ) : null}
          <StackedAccordions data={city.faqs} TitleTag="h2">
            <Trans>About {city.name}</Trans>
          </StackedAccordions>
          {city.id && (
            <CustomerReviews
              className="m-48-adaptive"
              lang={lang}
              isMobile={isMobile}
              withPopup={isMobile}
              queryParams={{ cityId: city.id }}
              title={
                <Trans>
                  <Plural
                    value={count}
                    _0=""
                    one="# review"
                    few="# reviews"
                    many="# reviews"
                    other="# reviews"
                  />{" "}
                  for audio tours in {cityName}
                </Trans>
              }
            />
          )}
          {lang === "en" && <LinkingsGroup linkings={linkings} city={city} lang={lang} />}
          {city?.description ? (
            <div
              className="City__seo m-48"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: seoHtml,
              }}
            />
          ) : null}
        </LazyHydrate>
        <JsonLd
          item={{
            "@context": "https://schema.org",
            "@type": "Product",
            url: `https://wegotrip.${DNSZone}${currentPath}`,
            sku: city.id,
            name: city.name,
            description: city.meta_description,
            brand: { "@type": "Brand", name: "WeGoTrip" },
            image: city.preview,
            ...(reviews.count
              ? {
                  aggregateRating: {
                    "@type": "AggregateRating",
                    ratingValue: reviews.averageRating,
                    reviewCount: reviews.count,
                  },
                }
              : {}),
            ...(reviews.count
              ? {
                  review: reviews.reviews.map(review => ({
                    "@type": "Review",
                    author: { "@type": "Person", name: review.name },
                    datePublished: review.date,
                    description: review.text,
                    reviewRating: {
                      "@type": "Rating",
                      bestRating: "5",
                      ratingValue: review.rating,
                      worstRating: "1",
                    },
                  })),
                }
              : {}),
            ...(products.count
              ? {
                  offers: products.products.map(product => ({
                    "@type": "Offer",
                    price: product.price,
                    priceCurrency: product.currencyCode,
                    url: `https://wegotrip.${DNSZone}${getProductLink(lang, product)}`,
                    availability: "https://schema.org/InStock",
                  })),
                }
              : {}),
          }}
        />
        <CookieFooter />
      </div>
    </Root>
  );
}

City.getInitialProps = withRedirectToKnownLang(
  // eslint-disable-next-line no-unused-vars
  async ({ req, res, match, history, location, store, scrollToTop, filters }) => {
    try {
      const cookies = get(req, "headers.cookie");
      const mobile = isMobileUserAgent(getUserAgent(req));
      store.dispatch({ type: types.FETCH_USER, cookies });

      const currency = await fetchDefaultCurrency(req);
      store.dispatch({ type: types.SET_DEFAULT_CURRENCY, defaultCurrency: currency });

      const { cityId } = match.params;
      const lang = getCurrentLanguage(match.params.lang);
      store.dispatch({ type: types.FETCH_PRODUCTS, cityId, lang });

      store.dispatch({ type: types.FETCH_LANGUAGES });

      const overview = await fetchOverview({ lang }, req);
      store.dispatch({ type: types.SET_OVERVIEW, data: overview });

      const {
        reviews: { count: reviewsCount },
      } = store.getState();
      if (!reviewsCount) {
        store.dispatch({
          type: types.FETCH_REVIEWS,
          lang,
          cityId,
          per_page: mobile ? 3 : 6,
        });
      }

      const { data: city } = await Api.get(
        `/api/v2/cities/${cityId}/?${toQueryString({
          lang,
          expand: "h1,meta_title,meta_description,description",
          preorder: true,
        })}`,
        { lang },
      );

      const cities = (
        await Api.get(`/api/v2/cities/?country=${city.country.id}&lang=${lang}&preorder=true`, {
          lang,
        })
      ).data.results.filter(item => item.id !== parseInt(cityId, 10));

      const seoHtml = replaceImgTagsInHtml(city.description) || "";

      let countryAttractions;
      if (city.country?.id) {
        countryAttractions = (
          await Api.get(
            `/api/v2/attractions/?${toQueryString({
              lang,
              country: city.country.id,
              preorder: true,
            })}`,
            { lang },
          )
        ).data;
      }

      const categories = city.categories
        ? city.categories.filter(cat => cat.itemsCount > 0 && cat.itemsCount < city.itemsCount)
        : [];

      /**
       * Redirecting to main page if no tours presented
       * Runs only if `bubbling` query param is set
       */
      if (get(req, "query.bubbling") && !city.itemsCount) {
        return {
          redirectTo: reverseUrl("main", {
            lang,
          }),
        };
      }

      const linkings = await fetchLinkings({ lang, id: cityId });
      return {
        cities,
        countryAttractions,
        destinations: overview.topCities,
        city,
        categories,
        mobile,
        seoHtml,
        path: req?.originalUrl,
        linkings,
      };
    } catch (error) {
      // TODO: serve 500 for not 404 errors
      return { statusCode: 404 };
    }
  },
);

const mapStateToProps = ({ products, user, reviews, filters }) => ({
  products: products || {},
  user,
  reviews,
  filters,
});

export default connect(mapStateToProps)(City);
