import { validTLDs } from "../constants/validTLDs";

export const validateEmail = email => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (!emailRegex.test(email)) {
    return false;
  }
  const emailParts = email.split(".");
  const tld = emailParts[emailParts.length - 1].toLowerCase();
  return validTLDs.includes(tld);
};
