import { t } from "@lingui/macro";

export default function getFilterButtonsProps({
  prefetchedCount,
  onReset = () => {},
  onSubmit = () => {},
  i18n,
}) {
  return {
    altBottomButtonProps: {
      children: i18n._(t`Clear`),
      isAlt: true,
      onClick: onReset,
    },
    bottomButtonProps: {
      className: "Accept-button",
      children: !prefetchedCount
        ? i18n._(t`No results`)
        : i18n._(t`Show results: ${prefetchedCount}`),
      willClose: true,
      theme: "black",
      onClick: onSubmit,
    },
  };
}
