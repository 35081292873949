/**
 * Categories for meta and description of `City` page in Russian
 * @param {String|Number} id - category id
 * @param {Object<String, Meta>} dictionary - object with meta strings to find string for meta tag
 * @returns {Meta?} - found `title` and `description` strings for head meta tags
 */
export default function getCategoryMetaDict(dictionary, id) {
  const safeId = String(id);
  return dictionary[safeId] || dictionary.default;
}
