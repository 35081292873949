import { t } from "@lingui/macro";
import { useSelector } from "react-redux";
import React, { useCallback, useMemo, useState } from "react";
import { init, updateFilters } from "../../../stores/filter/reducers";
import { formatDateRange } from "../../../functions/dateTimeUtils";
import memoizedState from "../../../functions/react/memoizedState";
import * as types from "../../../stores/types";
import Button from "../../Button";
import Calendar from "../../Calendar";

import { Icon } from "../../Icon";
import PopupOrDropdownAdapter from "../../PopupOrDropdownAdapter";
import getFilterButtonsProps from "../getFilterButtonsProps";
import ChevronIcon from "../../ChevronIcon";

const onDateSelectionChange = memoizedState({
  availableFrom: init.availableFrom,
  availableTill: init.availableTill,
});

function DateFilter({ lang, isMobile, i18n, dispatch, onApply = () => {} }) {
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const selectedDate = useSelector(({ availability }) => availability.selectedDate);
  const prefetchedCount = useSelector(({ products }) => products.prefetched.count);

  const handleSelect = useCallback(
    value => {
      const dateRangeFilter = {
        availableFrom: value?.from,
        availableTill: value?.to || value?.from,
      };
      onDateSelectionChange(dateRangeFilter, () => updateFilters(dispatch, dateRangeFilter));
    },
    [dispatch],
  );

  const applyFilters = () => {
    setButtonClicked(true);
    onApply();
  };

  const shouldShowSelection =
    (selectedDate?.from || selectedDate?.to) && (buttonClicked || !isPopupOpen);

  const selectedDateFormat = shouldShowSelection
    ? formatDateRange(selectedDate, lang)
    : i18n._(t`Date`);

  const popupButtons = useMemo(
    () =>
      getFilterButtonsProps({
        onReset: () => {
          dispatch({ type: types.SAVE_DATE_SELECT, date: null });
          dispatch({ type: types.RESET_DATE_FILTERS });
          setButtonClicked(false);
        },
        prefetchedCount,
        i18n,
      }),
    [dispatch, i18n, prefetchedCount],
  );

  return (
    <PopupOrDropdownAdapter
      isMobile={isMobile}
      title={i18n._(t`Date`)}
      triggerElement={
        <Button
          theme={shouldShowSelection ? "black" : "default"}
          icon={!isMobile && <ChevronIcon isOpen={isPopupOpen} />}
          className="PriceAndDate__button"
          size="M"
        >
          {selectedDateFormat}
        </Button>
      }
      {...popupButtons}
      ContentComponent={() => (
        <Calendar
          date={selectedDate}
          lang={lang}
          selectMode="range"
          numberOfMonths={isMobile ? 1 : 2}
          onSelect={handleSelect}
        />
      )}
      onOpened={() => setIsPopupOpen(true)}
      onClosed={() => {
        setIsPopupOpen(false);
        applyFilters();
      }}
    />
  );
}

export default DateFilter;
