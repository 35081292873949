import React, { useEffect, useState } from "react";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import Button from "../Button";
import { Icon } from "../Icon";
import classNames from "../../functions/classNames";
import { validateEmail } from "../../functions/validateEmail";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import getDomainZone from "../../functions/url/getDomainZone";
import { Api } from "../../functions/fetchFromApi";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import "./EmailSubscriptionSection.css";

const EmailSubscriptionSection = withI18n()(({ city, categoryId, i18n }) => {
  const lang = useCurrentLanguage();
  const DNSZone = getDomainZone(lang);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    let timer;
    if (showSuccessMessage) {
      timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showSuccessMessage]);

  const handleEmailChange = event => {
    const emailValue = event.target.value;
    setEmail(emailValue);
    setIsEmailValid(validateEmail(emailValue));
  };

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      const response = await Api.post(`/api/v1/sendpulseSubscription/category/`, {
        payload: {
          language: lang,
          country: city.country.id,
          city: city.id || 0,
          category: categoryId,
          email,
        },
      });

      if (response) {
        setStatusMessage(i18n._(t`Subscription successful!`));
        setIsError(false);
        setIsSubscribed(true);
        setShowSuccessMessage(true);
      } else {
        setIsError(true);
        setStatusMessage(i18n._(t`An unexpected error occurred. Please try again.`));
        setIsSubscribed(false);
      }
    } catch (error) {
      setIsError(true);
      if (error.message === "Request status: 409") {
        setStatusMessage(i18n._(t`The subscription has already been issued.`));
        setIsSubscribed(false);
      }
    }
  };
  const imageAnimationClass = isSubscribed ? "fade-in" : "fade-out";

  return (
    <div className="EmailSubscriptionSection">
      <div
        className={`EmailSubscriptionSection__image-container ${
          isSubscribed ? "EmailSubscriptionSection__image-container--animating" : ""
        }`}
      >
        <Icon
          name={isSubscribed ? "mail-subscriptionDone" : "mail-subscription"}
          width={isSubscribed ? 183 : 155}
          height={isSubscribed ? 195 : 171}
          className={`EmailSubscriptionSection__decorative-image ${imageAnimationClass}`}
        />
        <div
          className={classNames(
            "EmailSubscriptionSection__shadow",
            isSubscribed && "EmailSubscriptionSection__shadow_error",
          )}
        />
      </div>
      <div className="EmailSubscriptionSection__content-container">
        <div className="EmailSubscriptionSection__content-container_title">
          {i18n._(
            t`Your free ${inclineIfNeeded(
              city.name,
              "in",
              i18n.language,
            )} sightseeing self-guided audio tour is waiting.`,
          )}
        </div>
        <div className="EmailSubscriptionSection__content-container_subtitle">
          {i18n._(
            t`Get free audio tours, travel Spotify playlists, and other goodies in your email.`,
          )}
        </div>
        <div className="EmailSubscriptionSection__content-container_discription">
          {i18n._(t`By signing up, you agree to receive promotional emails on activities and insider tips.
            You can unsubscribe or withdraw your consent at any time with future effect. For more
            information, read our`)}{" "}
          <a
            className="EmailSubscriptionSection__link"
            href={`https://wegotrip.${DNSZone}/cancellation/`}
            target="_blank"
            rel="noopener"
          >
            {i18n._(t`Privacy statement.`)}
          </a>{" "}
        </div>
        <form className="EmailSubscriptionSection__subscription-form" onSubmit={handleSubmit}>
          <div className="EmailSubscriptionSection__subscription-form_input-container">
            <Icon
              name="mail"
              width="24"
              height="24"
              className="EmailSubscriptionSection__subscription-form_icon"
            />
            <input
              required
              className="EmailSubscriptionSection__subscription-form_input"
              type="email"
              placeholder={i18n._(t`e-mail`)}
              value={email}
              onChange={handleEmailChange}
              onBlur={() => {
                if (email.length > 0 && !isEmailValid) {
                  setIsError(true);
                  setStatusMessage(
                    i18n._(t`Please check the correctness of the entered email and try again`),
                  );
                } else {
                  setIsError(false);
                  setStatusMessage("");
                }
              }}
            />
          </div>
          <Button
            className="EmailSubscriptionSection__subscription-form_button"
            theme="black"
            type="submit"
            disabled={!isEmailValid}
          >
            {i18n._(t`Apply`)}
          </Button>
        </form>
        {(isError || showSuccessMessage) && (
          <div
            className={
              isError
                ? "EmailSubscriptionSection__status-message-error"
                : "EmailSubscriptionSection__status-message"
            }
          >
            {statusMessage}
          </div>
        )}
      </div>
    </div>
  );
});

export default EmailSubscriptionSection;
