import { cityIn as cityInPure, cityFrom as cityFromPure } from "lvovich";

function cityIn(cityName) {
  if (cityName === "Гайды для путешествий") {
    return "Гайдах для путешествий";
  }

  return cityInPure(cityName);
}

function cityFrom(cityName) {
  if (cityName === "Гайды для путешествий") {
    return "Гайдов для путешествий";
  }

  return cityFromPure(cityName);
}

/**
 * Meta for "Transfer" and "Multiple days tours" categories
 * @type {Meta}
 */
const transferAndMultiDays = {
  title: ({ city, category }) =>
    `${category} - Аудиогид в ${cityIn(city)} - Скачать и слушать аудиоэкскурсии в ${cityIn(city)}`,
  description: ({ city, category }) =>
    `${category}. Лучшие аудиогиды в ${cityIn(city)} от экспертов. Скачайте и слушайте онлайн аудиоэкскурсии по достопримечательностям и интересным местам ${cityFrom(
      city,
    )}.`,
};

/**
 * Categories for meta and description of `City` page in Russian
 * @type {Object<String, Meta>}
 */
export default {
  "1": {
    title: ({ city }) =>
      `Экскурсии по культурным и историческим местам ${cityFrom(city)} - Аудиогиды WeGoTrip`,
    description: ({ city }) =>
      `Лучшие экскурсии и аудиогиды по культурным и историческим местам ${cityFrom(
        city,
      )} от местных экспертов. Скачайте и слушайте онлайн аудиоэкскурсии по историческим местам ${cityFrom(
        city,
      )}.`,
  },
  "2": {
    title: ({ city }) => `Обзорные экскурсии и аудиогиды в ${cityIn(city)} - WeGoTrip`,
    description: ({ city }) =>
      `Лучшие обзорные экскурсии по самым интересным местам ${cityFrom(
        city,
      )} от местных экспертов. Скачайте и слушайте онлайн аудиоэкскурсии в ${cityIn(city)}.`,
  },
  "3": {
    title: ({ city }) => `Тематические туры и аудиогиды в ${cityIn(city)}`,
    description: ({ city }) =>
      `Лучшие тематические туры и аудиогиды по самым интересным местам ${cityFrom(
        city,
      )} от местных экспертов. Скачайте и слушайте онлайн аудиоэкскурсии в ${cityIn(city)}.`,
  },
  "4": {
    title: ({ city }) => `Семейные экскурсии и аудиогиды в ${cityIn(city)} - WeGoTrip`,
    description: ({ city }) =>
      `Лучшие семейные туры и аудиогиды по самым интересным местам ${cityFrom(
        city,
      )} от местных экспертов. Скачайте и слушайте онлайн аудиоэкскурсии в ${cityIn(city)}.`,
  },
  "5": {
    title: ({ city }) => `Гастрономические туры и аудиогиды в ${cityIn(city)}`,
    description: ({ city }) =>
      `Лучшие гастрономические туры и аудиогиды по самым интересным местам ${cityFrom(
        city,
      )} от местных экспертов. Скачайте и слушайте онлайн аудиоэкскурсии в ${cityIn(city)}.`,
  },
  "6": {
    title: ({ city }) => `Билеты и карты гостя в ${cityIn(city)} - WeGoTrip`,
    description: ({ city }) =>
      `Билеты и карты гостя в самые интересные места ${cityFrom(
        city,
      )}. Билеты, карты гостя и аудиогиды в одном приложении WeGoTrip.`,
  },
  "7": {
    title: ({ city }) => `Праздники и мероприятия в ${cityIn(city)} - WeGoTrip`,
    description: ({ city }) =>
      `Праздники и мероприятия в ${cityIn(
        city,
      )}. Билеты, карты гостя и аудиогиды в одном приложении WeGoTrip.`,
  },
  "8": transferAndMultiDays,
  "9": {
    title: ({ city }) =>
      `Бесплатные аудиогиды в ${cityIn(city)} - Скачать и слушать аудиоэкскурсии онлайн`,
    description: ({ city }) =>
      `Лучшие бесплатные аудиогиды по самым интересным местам ${cityFrom(
        city,
      )} от местных экспертов. Скачайте и слушайте онлайн аудиоэкскурсии в ${cityIn(city)}.`,
  },
  "10": transferAndMultiDays,
};
