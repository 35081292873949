import React from "react";
import "./ToggleSwitch.css";

export default function ToggleSwitch({ value, name, defaultChecked, onChange }) {
  return (
    <label className={`ToggleSwitch ${defaultChecked ? "on" : "off"}`}>
      <input
        className="ToggleSwitch__hidden"
        type="checkbox"
        {...{ value, name, onChange, defaultChecked }}
      />
      <span className="ToggleSwitch__slider" />
    </label>
  );
}
