import React, { useEffect, useState } from "react";
import uuid from "react-uuid";
import { isEmpty } from "lodash";
import { Trans } from "@lingui/macro";
import getDestinationLinksList from "../../functions/getDestinationLinksList";
import combineCollectionsWithCategories from "../../functions/collections/combineCollectionsWithCategories";
import extractLinks from "../../functions/html/extractLinks";
import Link from "../Link";
import "./Linking.css";
import { useMediaQuery } from "beautiful-react-hooks";
import { isSSR } from "../NoSSR";

/**
 * Block of links with title
 * @param {string} titleText - title of the block
 * @param {object[]} links - links to be displayed in block
 * @param {Function} setTextFormat - callback function to format a link text
 */

export function ToggleSwitch({ options, selectedOption, onToggle }) {
  const filteredOptions = options.filter(
    option => option.value !== null && option.value !== undefined && option.value !== "",
  );

  if (filteredOptions.length === 0) {
    return null;
  }

  return (
    <div className="Linking__toggleSwitch">
      {filteredOptions.map(option => (
        <div
          key={option.value}
          className={selectedOption === option.value ? "active" : ""}
          onClick={() => onToggle(option.value)}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
}

export function Linking({
  titleText,
  links,
  setTextFormat = text => text,
  toggleOptions,
  selectedOption,
  onToggle,
}) {
  if ((!links || links.length === 0) && (!toggleOptions || toggleOptions.length === 0)) {
    return null;
  }

  return (
    <div className="Linking">
      {titleText && <h3 className="Linking__title">{titleText}</h3>}

      {toggleOptions && toggleOptions.length > 0 && (
        <ToggleSwitch options={toggleOptions} selectedOption={selectedOption} onToggle={onToggle} />
      )}

      {links?.length > 0 && (
        <div className="Linking__block">
          {links.map(({ link, text }) => (
            <Link key={uuid()} external className="Linking__link" to={link} target="_blank">
              {setTextFormat(text)}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
}

export const addTicketsToursOnlyPopularBlock = (city, isDesktop) => text => {
  const maxLength = isDesktop ? 29 : 45;
  const toursText = " Tours";

  let truncatedText =
    isDesktop && text.length > maxLength - city.length - 2
      ? `${text.slice(0, maxLength - city.length - 5)}...`
      : text;

  if (`${city}: ${truncatedText}${toursText}`.length > maxLength) {
    if (`${city}: ${truncatedText}`.length > maxLength) {
      truncatedText = `${truncatedText.slice(0, maxLength - city.length - 5)}...`;
    }
    return (
      <>
        {city}: {truncatedText}
      </>
    );
  }

  return (
    <>
      {city}: {truncatedText} Tours
    </>
  );
};

export const addTicketsTours = text => {
  const suffix = "Tours";
  const maxLength = 24;

  return text.length + suffix.length > maxLength ? text : `${text} ${suffix}`;
};
const addCityName = city => text => `${city}: ${text}`;

export function LinkingsGroups({
  interests = [],
  city = {},
  cityAttractionsWithTickets = [],
  cityAttractionsWithoutTickets = [],
  travelersInterests = [],
  lang,
  cityName,
}) {
  const [showTickets, setShowTickets] = useState(false);
  const [showCategories, setShowCategories] = useState(true);
  const isDesktop = !isSSR && useMediaQuery("(min-width: 992px)");

  const interestsArray = Array.isArray(interests) ? interests : [];
  const travelersInterestsArray = Array.isArray(travelersInterests) ? travelersInterests : [];

  const interestLinkProps = {
    name: "category",
    destinationsParams: {
      categoryId: "id",
      categorySlug: "slug",
    },
    additionalParams: {
      cityId: city.id,
      citySlug: city.slug,
    },
    lang,
  };

  const ticketLinkProps = {
    name: "attraction-tickets-simple",
    destinationsParams: {
      attractionId: "id",
      attractionSlug: "slug",
    },
    lang,
  };

  const selectedAttractions = showTickets
    ? cityAttractionsWithTickets
    : cityAttractionsWithoutTickets;

  const toggleOptionsForTickets = [
    { label: <Trans>Self-guided tours</Trans>, value: false },
    { label: <Trans>Entrance tickets</Trans>, value: true },
  ];

  const [hasInterests, setHasInterests] = useState(false);
  const [hasTravelersInterests, setHasTravelersInterests] = useState(false);

  useEffect(() => {
    const hasInterests = interestsArray.length > 0;
    const hasTravelersInterests = travelersInterestsArray.length > 0;

    setHasInterests(hasInterests);
    setHasTravelersInterests(hasTravelersInterests);

    if (!hasInterests && hasTravelersInterests) {
      setShowCategories(false);
    } else if (hasInterests) {
      setShowCategories(true);
    }
  }, [interestsArray, travelersInterestsArray]);

  const toggleOptionsForCategories = [];
  if (hasInterests) {
    toggleOptionsForCategories.push({ label: <Trans>Categories</Trans>, value: true });
  }
  if (hasTravelersInterests) {
    toggleOptionsForCategories.push({ label: <Trans>Travelers Interests</Trans>, value: false });
  }

  const shouldDisplayCategoriesToggle = toggleOptionsForCategories.length > 0;
  const shouldDisplayTicketsToggle =
    cityAttractionsWithTickets.length > 0 || cityAttractionsWithoutTickets.length > 0;

  const categoryLinks =
    hasInterests || hasTravelersInterests
      ? getDestinationLinksList({
          ...interestLinkProps,
          destinations: showCategories
            ? interestsArray.map(interest => ({
                id: interest.category.id,
                title: interest.category.title,
                slug: interest.category.slug,
              }))
            : travelersInterestsArray.map(interest => ({
                id: interest.category.id,
                title: interest.category.title,
                slug: interest.category.slug,
              })),
          isDesktop,
        })
      : [];

  const attractionsLinks = selectedAttractions.length
    ? getDestinationLinksList({
        ...ticketLinkProps,
        destinations: selectedAttractions.map(attraction => ({
          id: attraction.id,
          title: attraction.name,
          slug: attraction.slug,
        })),
        isDesktop,
      })
    : [];

  return (
    <>
      {shouldDisplayCategoriesToggle && (
        <Linking
          titleText={<Trans>Things to do in {cityName}</Trans>}
          setTextFormat={addCityName(city.name)}
          toggleOptions={toggleOptionsForCategories}
          selectedOption={showCategories}
          links={categoryLinks}
          onToggle={setShowCategories}
        />
      )}

      {shouldDisplayTicketsToggle && (
        <Linking
          titleText={<Trans>Attractions in {cityName}</Trans>}
          setTextFormat={addTicketsToursOnlyPopularBlock(city.name, isDesktop)}
          toggleOptions={toggleOptionsForTickets}
          selectedOption={showTickets}
          links={attractionsLinks}
          onToggle={setShowTickets}
        />
      )}

      <Linking titleText="Popular on WeGoTrip" links={[]} />
    </>
  );
}

/**
 * Group of `Linking` blocks:
 * `Attractions in {city}`,
 * `Trending attractions in {country}`,
 * `Things to do in {city}`,
 * `Popular on WeGoTrip`.
 * @param {object} linkings - object with `attractions_city`, `attractions_world`,`attractions_country`, `city_categories`, `city_collections` to be displayed as Linking blocks
 * @param {object} city - current city
 * @param {string} lang - current language
 */
export default function LinkingsGroup({
  linkings = {},
  city = {},
  countryName = city.country?.name,
  lang,
  onlyPopularBlock = false,
}) {
  if (isEmpty(linkings)) return null;

  // should be used instead of `CollectionsAndCategories` when `SubCategories` will be ready
  // const CollectionsAndSubCategories = linkings.city_categories.reduce((things, { subcategories }) => {
  //   return things.concat(subcategories);
  // }, linkings.city_collections);

  const ticketLinkProps = {
    name: "attraction-tickets-simple",
    destinationsParams: {
      attractionId: "id",
      attractionSlug: "slug",
    },
    lang,
  };

  return (
    <div className="LinkingsGroup">
      {city.name && !onlyPopularBlock && (
        <>
          <Linking
            titleText={`Attractions in ${city.name}`}
            setTextFormat={addTicketsTours}
            links={getDestinationLinksList({
              destinations: linkings.attractions_city,
              ...ticketLinkProps,
            })}
          />
          <Linking
            titleText={`Things to do in ${city.name}`}
            setTextFormat={addCityName(city.name)}
            links={getDestinationLinksList({
              name: "category",
              destinations: combineCollectionsWithCategories(
                linkings.city_collections,
                linkings.city_categories,
              ),
              destinationsParams: {
                categoryId: "id",
                categorySlug: "slug",
              },
              additionalParams: {
                cityId: city.id,
                citySlug: city.slug,
              },
              lang,
            })}
          />
          <Linking
            titleText={`Interesting about ${city.name}`}
            links={extractLinks(linkings.interesting_about)}
          />
        </>
      )}
      {!onlyPopularBlock && (
        <Linking
          titleText={`Trending attractions in ${countryName}`}
          setTextFormat={addTicketsTours}
          links={getDestinationLinksList({
            destinations: linkings.attractions_country,
            ...ticketLinkProps,
          })}
        />
      )}
      <Linking
        titleText={<Trans>Popular on WeGoTrip</Trans>}
        links={getDestinationLinksList({
          destinations: linkings.attractions_world,
          ...ticketLinkProps,
        })}
      />
    </div>
  );
}
