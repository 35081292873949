/**
 * Collects given params from an object
 * @param {object} sourceObject
 * @param {string[]} entries - `keyName` for the result object and `searchedKey` to be collected from the `sourceObject`
 */
export default function collectObjectParams(sourceObject = {}, entries) {
  return entries.reduce((resultObject, [keyName, searchedKey]) => {
    if (sourceObject[searchedKey]) {
      // eslint-disable-next-line no-param-reassign
      resultObject[keyName] = sourceObject[searchedKey];
    }
    return resultObject;
  }, {});
}
