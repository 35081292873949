import isSSR from "../../constants/env/ssr";

export default function extractLinks(htmlString) {
  const result = [];
  if (!isSSR) {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    const aTags = doc.querySelectorAll("a");

    aTags.forEach(aTag => {
      result.push({ link: aTag.getAttribute("href"), text: aTag.textContent.trim() });
    });
  }

  return result;
}
