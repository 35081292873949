export const validTLDs = [
  "com",
  "org",
  "net",
  "int",
  "edu",
  "gov",
  "mil",
  "ru",
  "eu",
  "us",
  "uk",
  "de",
  "jp",
  "fr",
  "au",
  "br",
  "ca",
  "cn",
  "ae",
  "af",
  "al",
  "am",
  "ar",
  "at",
  "az",
  "bd",
  "be",
  "bg",
  "bh",
  "by",
  "ch",
  "cl",
  "cz",
  "dk",
  "eg",
  "es",
  "fi",
  "gr",
  "hk",
  "hu",
  "id",
  "ie",
  "il",
  "in",
  "ir",
  "it",
  "ke",
  "kg",
  "kr",
  "kz",
  "lk",
  "lt",
  "lv",
  "ma",
  "md",
  "me",
  "mk",
  "mx",
  "my",
  "ng",
  "nl",
  "no",
  "nz",
  "pk",
  "pl",
  "pt",
  "qa",
  "ro",
  "rs",
  "sa",
  "se",
  "sg",
  "si",
  "sk",
  "th",
  "tr",
  "tz",
  "ua",
  "ug",
  "uy",
  "uz",
  "ve",
  "vn",
  "za",
];
