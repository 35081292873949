import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useMediaQuery } from "beautiful-react-hooks";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import get from "lodash/get";
import { JsonLd } from "react-schemaorg";
import LazyHydrate from "react-lazy-hydration";
import routes from "../../routes";
import * as types from "../../stores/types";
import { isMobileUserAgent, getUserAgent } from "../../functions/userAgent";
import { Api } from "../../functions/fetchFromApi";
import detectRoute from "../../functions/route/detectRoute";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import { fetchDefaultCurrency } from "../../functions/currency";
import toQueryString from "../../functions/toQueryString";
import { sendListViewedEvent } from "../../functions/analytics";
import { fetchLinkings, fetchOverview } from "../../functions/fetchData";
import reverseUrl from "../../functions/reverseUrl";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import Root from "../../components/_Root";
import { isSSR } from "../../components/NoSSR";
import ScrollHook from "../../components/ScrollHook";
import TextExpand from "../../components/TextExpand";
import Hero from "../../components/Hero";
import StackedAccordions from "../../components/StackedAccordions";
import { TopAttractions } from "../../components/Attractions";
import { CustomerReviews } from "../../components/Reviews";
import { getProductLink } from "../../functions/getProductLink";
import replaceImgTagsInHtml from "../../functions/optimizeImagesInHtml";
import getDomainZone from "../../functions/url/getDomainZone";
import { CanonicalAuto } from "../../components/Canonical";
import { AlternateAuto } from "../../components/Alternate";
import LinkingsGroup from "../../components/Linking";
import ProductsGridAdapter from "../../components/ProductsGrid/ProductsGridAdapter";

import "../City/City.css";
import "./Tickets.css";
import ButtonlikeLinksBlock from "../../components/ButtonlikeLinksBlock";
import CookieFooter from "../../components/CookieFooter";

// TODO: use hooks for redux
function Tickets({
  mobile,
  city = {},
  cityProducts = [],
  attractionId,
  countryAttractions = [],
  countryCities = [],
  products = {},
  attraction,
  destinations,
  linkings,
  seoHtml = "",
  path = "",
  reviews = {},
  productsPerPage,
  currency,
}) {
  const { categoryId } = useParams();
  const lang = useCurrentLanguage();
  const DNSZone = getDomainZone(lang);
  const cityId = city.id;
  const currentPath = isSSR ? path : window.location.pathname;
  const { pathname } = useLocation();
  const route = detectRoute(
    routes.filter(r => /attraction-tickets/i.test(r.name)),
    pathname,
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isMobile = isSSR ? mobile : useMediaQuery("(max-width: 767px)");

  const category = categoryId
    ? city.categories.find(cat => cat.id === parseInt(categoryId, 10))
    : null;

  const cityNameNotInclined = city.name || "";
  const countryNameNotInclined = city.country?.name || "";

  const countryName = city.country ? inclineIfNeeded(city.country.name, "in", lang) : "";
  const cityName = city.name ? inclineIfNeeded(city.name, "in", lang) : "";
  let productListId;
  if (attraction && typeof attraction.id === "number") {
    productListId = `attraction_${attraction.slug}_${attraction.id}`;
  } else {
    productListId = `city_${city.slug}_${city.id}`;
  }

  const onProductListShown = () => {
    sendListViewedEvent(productListId, products.products, lang);
  };

  const defaultProductsGridProps = {
    listId: productListId,
    perPage: productsPerPage,
    isMobile,
    lang,
  };
  const findPriceByCurrency = (prices, currency) => {
    return prices.find(price => price.currencyCode === currency);
  };

  const lowestPrice = findPriceByCurrency(city.minPrice, currency);
  return (
    <Root stickyHeader searchInHeader destinations={destinations}>
      {route ? <AlternateAuto route={route} /> : null}
      {route ? <CanonicalAuto route={route} /> : route}
      <Hero
        withTickets
        className="m-20"
        city={city}
        lowestPrice={lowestPrice}
        // metaTitle={city.meta_title}
        // metaDescription={city.meta_description}
        category={category}
        attraction={attraction}
        current="attraction"
        theme="no-background"
      />
      <div className="Tickets Wrapper">
        <LazyHydrate whenVisible>
          <ScrollHook once="shown" showOn=".Tickets__products" onChanged={onProductListShown} />
          {!!products.products?.length && (
            <ProductsGridAdapter
              {...defaultProductsGridProps}
              {...products}
              selectParams={{ cityId, currency, attractionId }}
            />
          )}
          {!!get(city, "attractions.length") && (
            <div className="Tickets__section">
              <h2 className="Tickets__sectionTitle h1">
                <Trans>
                  Tickets to top attractions in {lang !== "ru" ? cityNameNotInclined : cityName}
                </Trans>
              </h2>
              <TopAttractions withTickets attractions={city.attractions} />
            </div>
          )}
          {!!cityProducts.length && (
            <ProductsGridAdapter
              {...cityProducts}
              selectParams={{ cityId, currency, preorder: true, separateTo: "cityProducts" }}
              title={<Trans>The most interesting tours in {cityNameNotInclined}</Trans>}
            />
          )}
          {get(countryAttractions, "length") ? (
            <div className="Tickets__section m-64 m-48-xs">
              <h2 className="Tickets__sectionTitle h1">
                <Trans>
                  Tickets to top attractions in{" "}
                  {lang !== "ru" ? countryNameNotInclined : countryName}
                </Trans>
              </h2>
              <TopAttractions withTickets attractions={countryAttractions} />
            </div>
          ) : null}
          <StackedAccordions data={attraction.about_ticket} TitleTag="h2">
            <Trans>About {attraction.name}</Trans>
          </StackedAccordions>
          {seoHtml && (
            <TextExpand
              html
              allowOriginalFormat
              shortText={seoHtml
                .slice(0, 300)
                .replace(/<\/?(tr|td|ul|li|p|b|div|span|a)[^>]*(>|$)/gi, "")}
              fullText={seoHtml}
            />
          )}
          <CustomerReviews
            withPopup
            withImage={false}
            perPage={20}
            className="m-48-adaptive"
            lang={lang}
            isMobile={isMobile}
            queryParams={{
              attraction: attractionId,
            }}
            title={<Trans>What people say about {attraction.name || cityName}</Trans>}
          />
          <StackedAccordions data={attraction.faq_ticket}>
            <Trans>FAQ about {attraction.name}</Trans>
          </StackedAccordions>
          <div className="Tickets__links-block">
            {city.categories?.length ? (
              <ButtonlikeLinksBlock
                titleText={<Trans>Other things to do in {cityName}</Trans>}
                links={city.categories.map(c => ({
                  link: reverseUrl("category", {
                    lang,
                    citySlug: city.slug,
                    cityId: city.id,
                    categoryId: c.id,
                    categorySlug: c.slug,
                  }),
                  text: c.title,
                }))}
              />
            ) : null}
            {countryCities.length ? (
              <ButtonlikeLinksBlock
                titleText={<Trans>Cities in {countryName}</Trans>}
                links={countryCities.map(countryCity => ({
                  link: reverseUrl("city", {
                    lang,
                    citySlug: countryCity.slug,
                    cityId: countryCity.id,
                  }),
                  text: countryCity.name,
                }))}
              />
            ) : null}
          </div>
        </LazyHydrate>
        {lang === "en" && <LinkingsGroup linkings={linkings} city={city} lang={lang} />}
        <JsonLd
          item={{
            "@context": "https://schema.org",
            "@type": "Product",
            url: `https://wegotrip.${DNSZone}${currentPath}`,
            sku: (attraction && attraction.id) || city.id,
            name: (attraction && attraction.name) || city.name,
            description: (attraction && attraction.meta_description) || city.meta_description,
            brand: { "@type": "Brand", name: "WeGoTrip" },
            image: (attraction && attraction.preview) || city.preview,
            ...(reviews.count
              ? {
                  aggregateRating: {
                    "@type": "AggregateRating",
                    ratingValue: reviews.averageRating,
                    reviewCount: reviews.count,
                  },
                }
              : {}),
            ...(reviews.count
              ? {
                  review: reviews.reviews.map(review => ({
                    "@type": "Review",
                    author: { "@type": "Person", name: review.name },
                    datePublished: review.date,
                    description: review.text,
                    reviewRating: {
                      "@type": "Rating",
                      bestRating: "5",
                      ratingValue: review.rating,
                      worstRating: "1",
                    },
                  })),
                }
              : {}),
            ...(products.count
              ? {
                  offers: products.products.map(product => ({
                    "@type": "Offer",
                    price: product.price,
                    priceCurrency: product.currencyCode,
                    url: `https://wegotrip.${DNSZone}${getProductLink(lang, product)}`,
                    availability: "https://schema.org/InStock",
                  })),
                }
              : {}),
          }}
        />
        {attraction.faq_ticket && (
          <JsonLd
            item={{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              mainEntity: attraction.faq_ticket.map(question => ({
                "@type": "Question",
                name: question.title,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: question.body,
                },
              })),
            }}
          />
        )}
        <CookieFooter />
      </div>
    </Root>
  );
}

Tickets.getInitialProps = withRedirectToKnownLang(
  // eslint-disable-next-line no-unused-vars
  async ({ req, res, match, history, location, store, scrollToTop }) => {
    try {
      const cookies = get(req, "headers.cookie");
      const mobile = isMobileUserAgent(getUserAgent(req));
      store.dispatch({ type: types.FETCH_USER, cookies });

      const currency = await fetchDefaultCurrency(req);
      store.dispatch({ type: types.SET_DEFAULT_CURRENCY, defaultCurrency: currency });

      const { attractionId } = match.params;
      const lang = getCurrentLanguage(match.params.lang);
      let { cityId } = match.params;
      let result = {};
      let seoHtml = "";

      store.dispatch({ type: types.FETCH_LANGUAGES });

      const overview = await fetchOverview({ lang }, req);
      store.dispatch({ type: types.SET_OVERVIEW, data: overview });

      if (attractionId) {
        const { data: attraction } = await Api.get(
          `/api/v2/attractions/${attractionId}/?${toQueryString({
            lang,
            expand:
              "h1_dupl,description_dupl,meta_title_dupl,meta_description_dupl,schedule,about_ticket,faq_ticket",
            preorder: true,
          })}`,
          {
            lang,
          },
        );
        result = { ...result, attraction };

        // Redirect to 404 page if no tickets presented
        if (!attraction.fee || !attraction.entrance) {
          return {
            redirectTo: reverseUrl("error", {
              lang,
            }),
          };
        }

        // Redirect from the long attraction route to the short one
        if (cityId) {
          return {
            redirectTo: reverseUrl("attraction-tickets-simple", {
              attractionId,
              attractionSlug: attraction.slug,
              lang,
            }),
          };
        }

        if (!cityId && attraction.city) {
          // eslint-disable-next-line require-atomic-updates
          cityId = attraction.city.id;
        }
        seoHtml = attraction.description_dupl;
      }

      const productsPerPage = mobile ? 4 : 8;
      // Products for the attraction with `attractionId`
      store.dispatch({
        type: types.FETCH_PRODUCTS,
        lang,
        currency,
        perPage: productsPerPage,
        attractionId,
      });

      // Products for the city`attractionId`
      store.dispatch({
        type: types.FETCH_PRODUCTS,
        cityId,
        lang,
        currency,
        perPage: productsPerPage,
        preorder: true,
        separateTo: "cityProducts",
      });

      const {
        reviews: { count: reviewsCount },
      } = store.getState();
      if (!reviewsCount) {
        store.dispatch({
          type: types.FETCH_REVIEWS,
          lang,
          attraction: attractionId,
          per_page: mobile ? 3 : 6,
        });
      }

      let city;
      let countryCities;
      if (cityId) {
        const { data: cityObj } = await Api.get(
          `/api/v2/cities/${cityId}/?${toQueryString({
            lang,
            expand: "h1,meta_title,meta_description,description,entrance,fee",
            preorder: true,
          })}`,
          { lang },
        );
        city = cityObj;
        countryCities = (
          await Api.get(
            `/api/v2/cities/?${toQueryString({
              country: city.country.id,
              lang,
              preorder: true,
            })}`,
            {
              lang,
            },
          )
        ).data.results;
      }

      let countryAttractions = [];
      if (get(city, "country.id")) {
        countryAttractions = (
          await Api.get(
            `/api/v2/attractions/?${toQueryString({
              lang,
              country: city.country.id,
              expand: "entrance,fee",
              preorder: true,
            })}`,
            {
              lang,
            },
          )
        ).data.results;
      }

      /**
       * Redirecting to main page if no tours presented
       * Runs only if `bubbling` query param is set
       */
      if (req && get(req, "query.bubbling") && city) {
        return {
          redirectTo: reverseUrl("main", {
            lang,
          }),
        };
      }

      const linkings = await fetchLinkings({ lang, id: cityId });

      result = {
        ...result,
        destinations: overview.topCities,
        city,
        productsPerPage,
        currency,
        countryAttractions,
        countryCities,
        attractionId,
        mobile,
        linkings,
        seoHtml: replaceImgTagsInHtml(seoHtml),
        path: req?.originalUrl,
      };

      return result;
    } catch (error) {
      // TODO: serve 500 for not 404 errors
      return { statusCode: 404 };
    }
  },
);

const mapStateToProps = ({ products, user, reviews }) => ({
  products,
  cityProducts: products.cityProducts,
  user,
  reviews,
});

export default connect(mapStateToProps)(Tickets);
