import React from "react";
import sendEvent from "../../../functions/analytics";
import reverseUrl from "../../../functions/reverseUrl";
import { updateFilters } from "../../../stores/filter/reducers";
import Button from "../../Button";

import "./CategoriesFilter.css";
import { addIfNotIncluded } from "../../../functions/array";

function sendCategorySelectedEvent(categoryTitle) {
  sendEvent("track", "Category Selected", { label: categoryTitle });
}

export default function CategoriesFilter({
  categories,
  isDesktop,
  cityId,
  citySlug,
  lang,
  dispatch,
  appliedCategories,
  onApply,
}) {
  if (!citySlug || !cityId || categories.length <= 1) {
    return null;
  }

  const handleButtonClick = (itemTitle, itemId, active) => {
    sendCategorySelectedEvent(itemTitle);
    const cleanIds = active
      ? appliedCategories.filter(id => id !== itemId)
      : addIfNotIncluded(appliedCategories, itemId);

    // Fetch products after update NOT only for (separateTo) filter use, also show the result in the products grid
    updateFilters(dispatch, { categoryId: cleanIds, storeBoth: true });
    onApply(cleanIds);
  };

  return (
    <section className="Wrapper__overload CategoriesFilter">
      <div className="CategoriesFilter__list">
        {categories
          .sort((a, b) => b.itemsCount - a.itemsCount)
          .slice(0, !isDesktop ? categories.length : 3)
          .map(({ title, itemsCount, id, slug: categorySlug }) => {
            const categoryId = String(id);
            const active = appliedCategories?.includes(categoryId);
            const buttonTheme = active ? "black" : "stroked";
            const buttonText = `${title} (${itemsCount})`;
            const to = active
              ? reverseUrl("city", { lang, citySlug, cityId })
              : reverseUrl("category", { lang, citySlug, cityId, categoryId, categorySlug });

            return (
              <Button
                key={categoryId}
                theme={buttonTheme}
                size="M"
                to={to}
                onClick={() => handleButtonClick(title, categoryId, active)}
              >
                {buttonText}
              </Button>
            );
          })}
      </div>
    </section>
  );
}
