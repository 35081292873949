import { t } from "@lingui/macro";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import * as types from "../../../stores/types";
import { init } from "../../../stores/filter/reducers";
import Button from "../../Button";
import { Icon } from "../../Icon";
import Popup from "../../Popup";
import getFilterButtonsProps from "../getFilterButtonsProps";
import GeneralFilterContent from "./GeneralFilterContent";

import "./GeneralFilter.css";

const { categoryId, durationGte, durationLte, ratingGte } = init;
const initGeneralFilters = { categoryId, durationGte, durationLte, ratingGte };

function isAnyFilterActive(filters, initialFilters) {
  return Object.keys(filters).some(key => {
    if (key === "storeBoth") return false;

    const currentValue = filters[key];
    const initialValue = initialFilters[key];
    if (Array.isArray(currentValue)) {
      return currentValue.length > 0;
    }
    return currentValue !== initialValue;
  });
}

function GeneralFilter({
  products,
  categories,
  lang,
  dispatch,
  isMobile,
  onApply = () => {},
  i18n,
}) {
  const prefetchedCount = useSelector(state => state.products.prefetched.count);
  const filters = useSelector(state => state.filters);
  const isActive = isAnyFilterActive(filters, init);

  const durationFilters = [
    { category: i18n._(t`Less than 1 hour`), durationMax: 60 },
    { category: i18n._(t`1-3 hours`), durationMin: 60, durationMax: 180 },
    { category: i18n._(t`4 hours - 1 day`), durationMin: 240, durationMax: 1440 },
    { category: i18n._(t`More than 1 day`), durationMin: 1440 },
  ];

  const availableDurations = durationFilters.filter(
    ({ durationMin, durationMax }) =>
      products.filter(
        product =>
          (!durationMax || product.durationMax <= durationMax) &&
          (!durationMin || product.durationMin >= durationMin),
      ).length > 0,
  );

  const popupButtons = useMemo(
    () =>
      getFilterButtonsProps({
        onReset: () => {
          dispatch({ type: types.RESET_GENERAL_FILTERS });
        },
        prefetchedCount,
        i18n,
      }),
    [dispatch, i18n, prefetchedCount],
  );

  return (
    <Popup
      theme={isMobile ? "bottomSheet" : ""}
      title={i18n._(t`Filters`)}
      triggerElement={
        <Button
          icon={<Icon name="filter" />}
          className={`GeneralFilter-button ${isActive ? "GeneralFilter-button--active" : ""}`}
          size="M"
        />
      }
      {...popupButtons}
      onClosed={onApply}
    >
      <GeneralFilterContent
        categories={categories}
        availableDurations={availableDurations}
        initGeneralFilters={initGeneralFilters}
        isMobile={isMobile}
        lang={lang}
        i18n={i18n}
      />
    </Popup>
  );
}

export default GeneralFilter;
