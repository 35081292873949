import React from "react";
import Accordion from "../Accordion";
import "./StackedAccordions.css";

/**
 * Stack of accordions with one title
 * @param {Object} $
 * @param {String} $.TitleTag - tag to wrap accordions titles in (default is `"div"`)
 * @param {Array[AtackedAccordionData]} $.data - data to display in accordions
 * @param {React::JSX} $.children - component title contents
 */
export default function StackedAccordions({ data: dataUnsafe, TitleTag = "div", children }) {
  const data = Array.isArray(dataUnsafe) ? dataUnsafe.filter(b => b.title && b.body) : [];
  return data.length ? (
    <>
      <h2 className="StackedAccordions__header h1">{children}</h2>
      <div>
        {data.map((item, index) => (
          <Accordion
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            html
            theme="simple"
            className="StackedAccordions__accordion"
            title={<TitleTag className="StackedAccordions__title">{item.title}</TitleTag>}
          >
            <div
              className="StackedAccordions__text"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: item.body,
              }}
            />
          </Accordion>
        ))}
      </div>
    </>
  ) : null;
}
