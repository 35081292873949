import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import classNames from "../../functions/classNames";
import "./ItemsExpand.css";
import { Icon } from "../Icon";

/**
 * Spoiler for the items
 * @param {Array} items - elements that should be shown
 * @param {Number} limit - amount of words to show in shrinked mode
 * @param {String} fullText - text to show on the button after expanding
 * @param {String} shortText - text to show on the button in shrinked mode
 * @param {String} className - additional CSS class name (will be added to root HTMLElement of the component)
 * @param {Boolean} expandOnDefault - will show all items if 'true'
 * @param {Function} $.onClick - user clicked "Expand" button
 */
export const ItemsExpand = ({
  items,
  fullText,
  shortText,
  limit = 3,
  className,
  expandOnDefault,
  onClick = () => {},
}) => {
  const [open, setOpen] = useState(expandOnDefault);
  const handleOnClick = () => {
    setOpen(!open);
    onClick();
  };

  const buttonText = open
    ? fullText || <Trans>See less</Trans>
    : shortText || <Trans>See more</Trans>;

  return (
    <>
      {open || expandOnDefault ? items : items?.slice(0, limit).map(item => item)}
      {items?.length > limit && (
        <div className={classNames("ItemsExpand", className)} onClick={handleOnClick}>
          {buttonText}
          <Icon name={`chevron/${open ? "up" : "down"}`} />
        </div>
      )}
    </>
  );
};
