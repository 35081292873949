import { t } from "@lingui/macro";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import * as types from "../../../stores/types";
import Button from "../../Button";
import { Icon } from "../../Icon";
import getFilterButtonsProps from "../getFilterButtonsProps";
import PopupOrDropdownAdapter from "../../PopupOrDropdownAdapter";
import { PriceSelection } from "./PriceSelection";

import "./PriceFilter.css";
import ChevronIcon from "../../ChevronIcon";

function PriceFilter({ currency, isMobile, i18n, dispatch, onApply = () => {} }) {
  const { maxPrice, minPrice } = useSelector(({ filters }) => ({
    maxPrice: filters.priceLte,
    minPrice: filters.priceGte,
  }));

  const { prefetchedCount, defaultMaxPrice } = useSelector(({ products }) => ({
    prefetchedCount: products.prefetched.count,
    defaultMaxPrice: Math.ceil(products.queryData?.maxPrice) || 100,
  }));

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const applyFilters = () => {
    onApply();
    setButtonClicked(true);
  };

  const shouldShowSelection = (maxPrice || minPrice) && (buttonClicked || !isPopupOpen);

  const popupButtons = useMemo(
    () =>
      getFilterButtonsProps({
        onReset: () => {
          dispatch({ type: types.RESET_PRICE_FILTERS });
          setButtonClicked(false);
        },
        prefetchedCount,
        i18n,
      }),
    [dispatch, i18n, prefetchedCount],
  );

  return (
    <PopupOrDropdownAdapter
      isMobile={isMobile}
      title={i18n._(t`Price`)}
      triggerElement={
        <Button
          theme={shouldShowSelection ? "black" : "default"}
          icon={!isMobile && <ChevronIcon isOpen={isPopupOpen} />}
          className="PriceAndDate__button"
          size="M"
        >
          {shouldShowSelection ? (
            <>
              {i18n._(t`Up to`)} {maxPrice || defaultMaxPrice}
            </>
          ) : (
            i18n._(t`Price`)
          )}
        </Button>
      }
      {...popupButtons}
      ContentComponent={() => (
        <PriceSelection currency={currency} dispatch={dispatch} defaultMaxPrice={defaultMaxPrice} />
      )}
      onOpened={() => setIsPopupOpen(true)}
      onClosed={() => {
        setIsPopupOpen(false);
        applyFilters();
      }}
    />
  );
}

export default PriceFilter;
