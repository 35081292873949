/**
 * Meta for "Transfer" and "Multiple days tours" categories
 * @type {Meta}
 */
const transferAndMultiDays = {
  title: ({ city, category }) =>
    `${category} - Audio guide in ${city} - Download and listen to the tour in ${city}`,
  description: ({ city, category }) =>
    `${category}. Best audio guides in ${city} from experts. Download and listen to online audio tours to explore sights and interesting places of ${city}.`,
};

/**
 * Meta for all categories excepting "Transfer" and "Multiple days tours"
 * @type {Meta}
 */
const general = {
  title: ({ city, category }) => {
    return `${category} ${city} - Self-Guided Walking tours`;
  },
  description: ({ city, category }) =>
    `${category}. Self-guided walking tours and tickets to the sights of ${city}. Audio guides to the best places to walk in ${city} from experts.`,
};

/**
 * Categories for meta and description of `City` page in Eglish
 * @type {Object<String, Meta>}
 */
export default {
  default: {
    title: ({ city, category }) =>
      `${category} — Self-Guided Walking tours of ${city} — The best walks in ${city}`,
    description: ({ city, category }) =>
      `${category}. Self-guided walking tours and tickets to the sights of ${city}. Audio guides to the best places to walk in ${city} from experts.`,
  },
  "1": general,
  "2": general,
  "3": general,
  "4": general,
  "5": general,
  "6": general,
  "7": general,
  "8": transferAndMultiDays,
  "9": general,
  "10": transferAndMultiDays,
};
