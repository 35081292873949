import React, { useEffect, useMemo, useState } from "react";
import { Trans } from "@lingui/macro";
import { DayPicker } from "react-day-picker";
import isAfter from "date-fns/isAfter";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { isEqual } from "date-fns";
import { SpinnerOverlay } from "../Spinner";
import Price from "../Price";
import { getFnsLocale } from "../../functions/dateTimeUtils";
import classNames from "../../functions/classNames";
import * as types from "../../stores/types";
import { getDayWithCaption, getFirstAvailableDate, getUnavailableDates } from "./calendarHelpers";

import "react-day-picker/dist/style.css";
import "./Calendar.css";
import { BOOKING_AVAILABILITY_END_DATE, TODAY_START } from "../../constants";

let oldestLoaded = new Date();

export const DAY_STATUSES = { day_off: <Trans>closed</Trans>, sold_out: <Trans>sold out</Trans> };

/**
 * `DayCaption`
 * @param {Date} currentDay - date rendered in calendar
 * @param {String} currencyCode - product currencyCode from server
 */
export const DayCaption = ({ currentDay, currencyCode }) => {
  let caption = null;
  if (currentDay) {
    const { status, min_prices: [{ min_price: minPrice } = {}] = [{}] } = currentDay;

    const price = Number(minPrice);
    if (status === "available" && price >= 0) {
      caption = <Price value={price} currencyCode={currencyCode} />;
    } else {
      caption = DAY_STATUSES[currentDay?.status] || null;
    }
  }
  return caption && <div className="Calendar__day-caption">{caption}</div>;
};

/**
 * `Calendar`
 * @param {Date} date - selected date
 * @param {Function} onSelect - event(callback) fired when a range (or a part of the range) is selected
 * @param {Number} numberOfMonths - the number of displayed months.
 * @param {Date[]} onMonthChange - event(callback) fired when `next`/`prev` buttons are clicked
 * @param {String} selectMode - mode to select days: `single` | `multiple` | `range` | `default`
 * @param {String} lang - language ot the calendar
 * @param {String} className - css class that should be set as the `Calendar` wrapper
 */
const Calendar = ({
  date,
  selectMode = "single",
  onMonthChange = () => {},
  className,
  onSelect = () => {},
  today = new Date(),
  lang,
  ...props
}) => {
  const dispatch = useDispatch();
  const { days, loading, error } = useSelector(
    ({ availability }) => ({
      days: availability.days || [],
      error: availability.error,
      loading: availability.loading,
    }),
    shallowEqual,
  );

  const DateWithCaption = useMemo(
    () => getDayWithCaption(days, props.currencyCode, BOOKING_AVAILABILITY_END_DATE),
    [days, props.currencyCode, BOOKING_AVAILABILITY_END_DATE],
  );

  const [month, setMonth] = useState(() => {
    const firstAvailableDate = days.length ? getFirstAvailableDate(days) : today;
    return firstAvailableDate || today;
  });

  const unavailableDates = getUnavailableDates(days, today, error);
  const disabledDates = [
    ...unavailableDates,
    { after: BOOKING_AVAILABILITY_END_DATE },
    { before: today },
  ];

  return (
    <div className={classNames("Calendar", className)}>
      <DayPicker
        fixedWeeks
        showOutsideDays
        locale={getFnsLocale(lang)}
        mode={selectMode}
        fromMonth={today}
        month={month}
        selected={date}
        disabled={disabledDates}
        weekStartsOn={1}
        {...props}
        components={{ DayContent: DateWithCaption }}
        onSelect={selected => {
          onSelect(selected);
          dispatch({ type: types.SAVE_DATE_SELECT, date: selected });
        }}
        onMonthChange={firstDate => {
          setMonth(firstDate);
          onMonthChange(firstDate, isAfter(firstDate, new Date()));
        }}
      />
      {loading === "days" && <SpinnerOverlay />}
    </div>
  );
};

export default Calendar;
