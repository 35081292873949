/* eslint-disable react/jsx-key */
import React from "react";
import get from "lodash/get";
import { Trans } from "@lingui/macro";
import add from "date-fns/add";
import getDay from "date-fns/getDay";
import startOfDay from "date-fns/startOfDay";
import format from "date-fns/format";
import differenceInSeconds from "date-fns/differenceInSeconds";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import "./OpenHours.css";
import Popup from "../Popup";

function OpenHours({ product }) {
  const schedule = get(product, "schedule.0");
  const lang = useCurrentLanguage();
  if (!schedule?.openingHours) return null;

  const days = [
    <Trans>Monday</Trans>,
    <Trans>Tuesday</Trans>,
    <Trans>Wednesday</Trans>,
    <Trans>Thursday</Trans>,
    <Trans>Friday</Trans>,
    <Trans>Saturday</Trans>,
    <Trans>Sunday</Trans>,
  ];

  let text;
  let time;

  const timeIntervals = Object.entries(schedule.openingHours)[
    getDay(new Date()) === 0 ? 6 : getDay(new Date()) - 1
  ][1].timeIntervals[0];

  if (timeIntervals) {
    const timeStartUnformatted = add(startOfDay(new Date()), {
      hours: timeIntervals.openFrom.slice(0, 2),
      minutes: timeIntervals.openFrom.slice(3, 5),
    });

    const timeStart =
      lang === "ru"
        ? format(timeStartUnformatted, "H:mm")
        : format(
            timeStartUnformatted,
            timeIntervals.openFrom.slice(3, 5) === "00" ? "ha" : "h:mma",
          );

    const timeEndUnformatted = add(timeStartUnformatted, {
      hours: timeIntervals.openForHours,
      minutes: timeIntervals.openForMinutes,
    });

    const timeEnd =
      lang === "ru"
        ? format(timeEndUnformatted, "H:mm")
        : format(timeEndUnformatted, timeIntervals.openForMinutes ? "h:mma" : "ha");

    text =
      differenceInSeconds(timeEndUnformatted, new Date()) < 0 ? (
        <Trans>Closed now:</Trans>
      ) : (
        <Trans>Open now:</Trans>
      );
    time = ` ${timeStart} - ${timeEnd}`;
  } else {
    text = <Trans>Day off</Trans>;
    time = "";
  }

  const arr = Object.entries(schedule.openingHours).map((item, index) => {
    const day = days[index];
    let timeStart;
    let timeEnd;
    if (item[1].timeIntervals[0]) {
      const timeStartUnformatted = item[1].timeIntervals[0]
        ? add(startOfDay(new Date()), {
            hours: item[1].timeIntervals[0].openFrom.slice(0, 2),
            minutes: item[1].timeIntervals[0].openFrom.slice(3, 5),
          })
        : null;
      timeStart =
        lang === "ru"
          ? format(timeStartUnformatted, "H:mm")
          : format(
              timeStartUnformatted,
              item[1].timeIntervals[0].openFrom.slice(3, 5) === "00" ? "ha" : "h:mma",
            );
      const timeEndUnformatted = add(timeStartUnformatted, {
        hours: item[1].timeIntervals[0].openForHours,
        minutes: item[1].timeIntervals[0].openForMinutes,
      });
      timeEnd =
        lang === "ru"
          ? format(timeEndUnformatted, "H:mm")
          : format(timeEndUnformatted, item[1].timeIntervals[0].openForMinutes ? "h:mma" : "ha");
    }
    const workingTime = item[1].timeIntervals[0] ? (
      `${timeStart} - ${timeEnd}`
    ) : (
      <Trans>Day off</Trans>
    );
    return [day, workingTime];
  });

  return (
    <div className="OpenHours">
      <div className="h-30 m-24 OpenHours__title">
        <Popup
          className="OpenHours__popup"
          title={<Trans>Working hours</Trans>}
          triggerElement={
            <div className="OpenHours__hours">
              <span className="OpenHours__text">{text}</span>
              <span className="OpenHours__time">{time}</span>
            </div>
          }
        >
          {arr.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="OpenHours__wrapper">
              <span className="OpenHours__popupText">{item[0]}</span>
              <span className="OpenHours__popupText">{item[1]}</span>
            </div>
          ))}
        </Popup>
      </div>
    </div>
  );
}

export default OpenHours;
