import React from "react";
import classNames from "../../../functions/classNames";
import "./InputLabel.css";

/**
 * Input label
 * @param {Object} $
 * @param {Array} $.children - label text
 * @param {Boolean} $.margin - if margin after label is required
 */
export default function InputLabel({ margin = false, children, theme, error }) {
  return (
    <div
      className={classNames(
        "t-15",
        "InputLabel",
        margin && "InputLabel--margin",
        theme && `InputLabel--${theme}`,
        error && "InputLabel--error",
      )}
    >
      {children}
    </div>
  );
}
