import React, { useState } from "react";
import { withI18n } from "@lingui/react";
import { t } from "@lingui/macro";
import { useSelector } from "react-redux";
import withLabel from "../../../Input/withLabel";
import Input from "../../../Input";
import { updateFilters } from "../../../../stores/filter/reducers";

const PriceSlider = ({ max, localPrice, onChange, onBlur, dispatch, setLocalPrice }) => {
  const [isSliderActive, setIsSliderActive] = useState(false);

  const handleRangeEnd = (e, type) => {
    setIsSliderActive(false);
    onBlur(e, type);
  };

  const handleTrackClick = e => {
    const trackBounds = e.currentTarget.getBoundingClientRect();
    const trackWidth = trackBounds.width;
    const clickPosition = e.clientX - trackBounds.left;

    const minPos = (localPrice.min / max) * trackWidth;
    const maxPos = ((localPrice.max || max) / max) * trackWidth;

    const distanceToMin = Math.abs(clickPosition - minPos);
    const distanceToMax = Math.abs(clickPosition - maxPos);
    const closestHandle = distanceToMin < distanceToMax ? "min" : "max";

    const newValue = Math.round((clickPosition / trackWidth) * max);

    setLocalPrice(prev => ({ ...prev, [closestHandle]: newValue }));

    const updatedFilter = { [`price${closestHandle === "min" ? "Gte" : "Lte"}`]: newValue };
    updateFilters(dispatch, updatedFilter);
  };

  return (
    <div className="PriceFilter__slider" onClick={handleTrackClick}>
      <div className="PriceFilter__slider-track" />
      <div
        className="PriceFilter__slider-range"
        style={{
          left: `${(localPrice.min / max) * 100}%`,
          width: `${((localPrice.max - localPrice.min) / max) * 100}%`,
        }}
      />
      {["min", "max"].map(type => (
        <input
          key={type}
          type="range"
          min="0"
          max={max}
          value={type === "min" ? localPrice.min || "0" : localPrice.max || max}
          className={`PriceFilter__range PriceFilter__range--${type}`}
          onChange={e => onChange(e, type)}
          onMouseDown={() => setIsSliderActive(true)}
          onMouseUp={e => handleRangeEnd(e, type)}
          onTouchStart={() => setIsSliderActive(true)}
          onTouchEnd={e => handleRangeEnd(e, type)}
          onBlur={e => {
            if (!isSliderActive) {
              onBlur(e, type);
            }
          }}
        />
      ))}
    </div>
  );
};

const PriceInputWithLabel = withLabel(Input);

const PriceInput = withI18n()(({ type, currency, value, onChange, onBlur, i18n }) => {
  return (
    <PriceInputWithLabel
      id={`${type}PriceInput`}
      className="PriceFilter__price_input"
      label={type === "min" ? i18n._(t`Minimum`) : i18n._(t`Maximum`)}
      type="number"
      value={value !== null ? value : ""}
      onChange={e => onChange(e, type)}
      onBlur={e => onBlur(e, type)}
    >
      <span className="PriceFilter__price_label-currencyCode">{currency}</span>
    </PriceInputWithLabel>
  );
});

const sanitizePriceValue = value => {
  const intValue = parseInt(value, 10);
  return Number.isNaN(intValue) ? null : intValue;
};

export const PriceSelection = React.memo(({ currency, dispatch, defaultMaxPrice }) => {
  const filtersState = useSelector(({ filters }) => filters);

  const getInitialPrice = type => filtersState[`price${type}`] || 0;

  const [localPrice, setLocalPrice] = useState({
    min: Math.floor(getInitialPrice("Gte")),
    max: Math.ceil(getInitialPrice("Lte") || defaultMaxPrice),
  });

  const handleInputChange = (e, type) => {
    const value = sanitizePriceValue(e.target.value);
    setLocalPrice(prev => ({ ...prev, [type]: value }));
  };

  const handleInputBlur = (e, type) => {
    const value = sanitizePriceValue(e.target.value);
    const newPrice = { ...localPrice };

    if (value !== null) {
      if (type === "min") {
        newPrice.min = value > localPrice.max ? localPrice.max - 1 : Math.max(newPrice.min);
      } else if (type === "max") {
        newPrice.max = value < localPrice.min ? localPrice.min + 1 : Math.max(newPrice.max);
      }
    }

    setLocalPrice(prev => ({ ...prev, [type]: newPrice }));

    const updatedFilter = { [`price${type === "min" ? "Gte" : "Lte"}`]: newPrice[type] };
    updateFilters(dispatch, updatedFilter);
  };

  return (
    <div className="PriceFilter">
      <PriceSlider
        max={defaultMaxPrice}
        localPrice={localPrice}
        dispatch={dispatch}
        setLocalPrice={setLocalPrice}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
      />
      <div className="PriceFilter__price">
        {["min", "max"].map(type => (
          <PriceInput
            key={type}
            type={type}
            currency={currency}
            value={localPrice[type]}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
          />
        ))}
      </div>
    </div>
  );
});
