import React from "react";
import uuid from "react-uuid";
import Button from "../Button";
import "./ButtonlikeLinksBlock.css";

/**
 * Block of buttonlike destinations links with title
 * @param {object} $
 * @param {string} $.titleText - title of the block
 * @param {object[]} $.links - links to be displayed in block
 */
export default function ButtonlikeLinksBlock({ titleText, links }) {
  return (
    !!links?.length && (
      <div className="ButtonlikeLinksBlock">
        {titleText && <h2 className="ButtonlikeLinksBlock__title">{titleText}</h2>}
        <div className="ButtonlikeLinksBlock__buttons">
          {links.map(({ link, text }) => (
            <Button key={uuid()} external className="Button--stroked" to={link}>
              {text}
            </Button>
          ))}
        </div>
      </div>
    )
  );
}
