/* eslint-disable import/no-cycle */
import React, { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "beautiful-react-hooks";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import get from "lodash/get";
import { JsonLd } from "react-schemaorg";
import LazyHydrate from "react-lazy-hydration";
import * as types from "../../stores/types";
import routes from "../../routes";
import { isMobileUserAgent, getUserAgent } from "../../functions/userAgent";
import { Api } from "../../functions/fetchFromApi";
import inclineIfNeeded from "../../functions/inclineIfNeeded";
import detectRoute from "../../functions/route/detectRoute";
import { fetchDefaultCurrency } from "../../functions/currency";
import toQueryString from "../../functions/toQueryString";
import { sendListViewedEvent } from "../../functions/analytics";
import { fetchLinkings, fetchOverview } from "../../functions/fetchData";
import reverseUrl from "../../functions/reverseUrl";
import replaceImgTagsInHtml from "../../functions/optimizeImagesInHtml";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import Root from "../../components/_Root";
import StackedAccordions from "../../components/StackedAccordions";
import TextExpand from "../../components/TextExpand";
import { isSSR } from "../../components/NoSSR";
import ScrollHook from "../../components/ScrollHook";
import { CanonicalAuto } from "../../components/Canonical";
import Hero from "../../components/Hero";
import { TopAttractions } from "../../components/Attractions";
import { CustomerReviews } from "../../components/Reviews";
import { getProductLink } from "../../functions/getProductLink";
import getDomainZone from "../../functions/url/getDomainZone";
import { AlternateAuto } from "../../components/Alternate";
import ProductsGridAdapter from "../../components/ProductsGrid/ProductsGridAdapter";
import LinkingsGroup from "../../components/Linking";

import "./Attraction.css";
import ButtonlikeLinksBlock from "../../components/ButtonlikeLinksBlock";
import CookieFooter from "../../components/CookieFooter";
import { OnDemandToursBanner } from "../../components/OnDemandToursBanner";

// TODO: use hooks for redux
function Attraction({
  city = {},
  countryAttractions,
  products = {},
  countryCities,
  cityProducts,
  productsPerPageCity,
  attraction,
  user,
  destinations,
  seoHtml = "",
  path = "",
  reviews = {},
  attractionId,
  linkings,
  productsPerPage,
  currency,
}) {
  const lang = useCurrentLanguage();
  const DNSZone = getDomainZone(lang);
  const cityId = city.id;
  const currentPath = isSSR ? path : window.location.pathname;
  const { pathname } = useLocation();
  const route = detectRoute(
    routes.filter(r => ["attraction-simple", "attraction"].includes(r.name)),
    pathname,
  );

  const memoizedCity = useMemo(
    () => ({
      ...city,
    }),
    [city],
  );

  const memoizedAttraction = useMemo(
    () => ({
      ...attraction,
    }),
    [attraction],
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isMobile = !isSSR && useMediaQuery("(max-width: 767px)");
  const cityName = useMemo(() => inclineIfNeeded(city.name, "in", lang), [city.name, lang]);
  const countryName = useMemo(
    () => inclineIfNeeded(city.country?.name, "in", lang),
    [city.country, lang],
  );

  const cityNameNotInclined = city.name || "";
  const countryNameInclined = city.country?.name || "";

  const productListId = `attraction_${attraction?.slug}_${attraction?.id}`;

  /**
   * Product list became visible in viewport
   */
  const onProductListShown = useCallback(() => {
    sendListViewedEvent(productListId, products.products, lang);
  }, [productListId, products.products, lang]);

  const defaultProductsGridProps = {
    listId: productListId,
    perPage: productsPerPage,
    isMobile,
    lang,
  };

  const findPriceByCurrency = (prices, currency) => {
    return prices.find(price => price.currencyCode === currency);
  };

  const lowestPrice = findPriceByCurrency(memoizedCity.minPrice, currency);

  return (
    <Root stickyHeader searchInHeader destinations={destinations}>
      {route ? <AlternateAuto route={route} /> : null}
      {route ? <CanonicalAuto route={route} /> : null}
      <Hero
        className="m-20"
        city={memoizedCity}
        lowestPrice={lowestPrice}
        initialMetaTitle={memoizedCity.meta_title}
        initialMetaDescription={memoizedCity.meta_description}
        attraction={memoizedAttraction}
        current="attraction"
        theme="no-background"
      />
      <div className="Wrapper Attractions">
        <LazyHydrate whenVisible>
          <ScrollHook once="shown" showOn=".City__products" onChanged={onProductListShown} />
          {/* this block was temporarily removed */}
          {/* <OnDemandToursBanner lang={lang} userId={user.user.id} /> */}

          <ProductsGridAdapter
            {...defaultProductsGridProps}
            {...products}
            selectParams={{ cityId, currency, attractionId }}
            showMorePaginate={!isMobile}
          />

          {!!city.attractions.filter(attraction => attraction.itemsCount > 0).length && (
            <div className="m-64 m-48-xs">
              <h2 className="h1 m-32 m-64-t">
                <Trans>Top attractions in {cityName}</Trans>
              </h2>
              <TopAttractions attractions={city.attractions} />
            </div>
          )}

          {!!cityProducts?.products?.length && (
            <ProductsGridAdapter
              {...defaultProductsGridProps}
              {...cityProducts}
              selectParams={{ cityId, currency, preorder: true, separateTo: "cityProducts" }}
              perPage={productsPerPageCity}
              title={
                <Trans>
                  The most interesting tours in {lang === "ru" ? cityNameNotInclined : cityName}
                </Trans>
              }
            />
          )}

          {get(countryAttractions, "results.length") ? (
            <div className="m-64 m-48-xs">
              <h2 className="h1 m-32 m-64-t">
                <Trans>
                  Top attractions in {lang === "ru" ? countryNameInclined : countryName}
                </Trans>
              </h2>
              <TopAttractions attractions={countryAttractions.results} />
            </div>
          ) : null}
          <StackedAccordions data={attraction?.abouts} TitleTag="h2">
            <Trans>About {attraction?.name}</Trans>
          </StackedAccordions>
          {seoHtml && (
            <TextExpand
              html
              allowOriginalFormat
              shortText={seoHtml
                .slice(0, 300)
                .replace(/<\/?(tr|td|ul|li|p|b|div|span|a)[^>]*(>|$)/gi, "")}
              fullText={seoHtml}
            />
          )}
          <CustomerReviews
            withPopup={isMobile}
            withImage={false}
            perPage={20}
            className="m-48-adaptive"
            lang={lang}
            isMobile={isMobile}
            queryParams={{
              attraction: attractionId,
            }}
            title={<Trans>What people say about {attraction?.name || cityName}</Trans>}
          />
          <StackedAccordions data={attraction?.faqs}>
            <Trans>FAQ about {attraction?.name}</Trans>
          </StackedAccordions>
          <div className="Attractions__links-block">
            {city && city.categories && city.categories.length ? (
              <ButtonlikeLinksBlock
                titleText={<Trans>Other things to do in {cityName}</Trans>}
                links={city.categories.map(category => ({
                  link: reverseUrl("category", {
                    lang,
                    citySlug: city.slug,
                    cityId,
                    categoryId: category.id,
                    categorySlug: category.slug,
                  }),
                  text: category.title,
                }))}
              />
            ) : null}

            {countryCities?.length ? (
              <ButtonlikeLinksBlock
                titleText={<Trans>Cities in {countryName}</Trans>}
                links={countryCities.map(countryCity => ({
                  link: reverseUrl("city", {
                    lang,
                    citySlug: countryCity.slug,
                    cityId: countryCity.id,
                  }),
                  text: countryCity.name,
                }))}
              />
            ) : null}
          </div>
        </LazyHydrate>
        {lang === "en" && <LinkingsGroup linkings={linkings} city={city} lang={lang} />}
        <JsonLd
          item={{
            "@context": "https://schema.org",
            "@type": "Product",
            url: `https://wegotrip.${DNSZone}${currentPath}`,
            sku: attraction?.id,
            name: attraction?.name,
            description: attraction?.meta_description,
            brand: { "@type": "Brand", name: "WeGoTrip" },
            image: attraction?.preview,
            ...(reviews.count
              ? {
                  aggregateRating: {
                    "@type": "AggregateRating",
                    ratingValue: reviews.averageRating,
                    reviewCount: reviews.count,
                  },
                }
              : {}),
            ...(reviews.count
              ? {
                  review: reviews.reviews.map(review => ({
                    "@type": "Review",
                    author: { "@type": "Person", name: review.name },
                    datePublished: review.date,
                    description: review.text,
                    reviewRating: {
                      "@type": "Rating",
                      bestRating: "5",
                      ratingValue: review.rating,
                      worstRating: "1",
                    },
                  })),
                }
              : {}),
            ...(products.count
              ? {
                  offers: products.products.map(product => ({
                    "@type": "Offer",
                    price: product.price,
                    priceCurrency: product.currencyCode,
                    url: `https://wegotrip.${DNSZone}${getProductLink(lang, product)}`,
                    availability: "https://schema.org/InStock",
                  })),
                }
              : {}),
          }}
        />
        {attraction?.faqs && (
          <JsonLd
            item={{
              "@context": "https://schema.org",
              "@type": "FAQPage",
              mainEntity: attraction?.faqs.map(question => ({
                "@type": "Question",
                name: question.title,
                acceptedAnswer: {
                  "@type": "Answer",
                  text: question.body,
                },
              })),
            }}
          />
        )}
        <CookieFooter />
      </div>
    </Root>
  );
}

Attraction.getInitialProps = withRedirectToKnownLang(async ({ req, match, store }) => {
  try {
    const cookies = get(req, "headers.cookie");
    const mobile = isMobileUserAgent(getUserAgent(req));
    store.dispatch({ type: types.FETCH_USER, cookies });

    const currency = await fetchDefaultCurrency(req);
    store.dispatch({ type: types.SET_DEFAULT_CURRENCY, defaultCurrency: currency });

    const { attractionId } = match.params;
    const lang = getCurrentLanguage(match.params.lang);
    let { cityId } = match.params;
    let seoHtml = "";

    store.dispatch({ type: types.FETCH_LANGUAGES });

    const overview = await fetchOverview({ lang }, req);
    store.dispatch({ type: types.SET_OVERVIEW, data: overview });

    const { data: attraction } = await Api.get(
      `/api/v2/attractions/${attractionId}/?${toQueryString({
        lang,
        expand: "meta_title,meta_description,schedule,abouts,faqs",
        preorder: true,
      })}`,
      { lang },
    );

    // Redirect from the long attraction route to the short one
    if (cityId) {
      return {
        redirectTo: reverseUrl("attraction-simple", {
          attractionId,
          attractionSlug: attraction.slug,
          lang,
        }),
      };
    }

    if (!cityId && attraction.city) {
      cityId = attraction.city.id;
    }

    seoHtml = attraction.description;

    const productsPerPage = mobile ? 4 : 20;
    const productsPerPageCity = mobile ? 4 : 8;
    // Products for the attraction with `attractionId`
    store.dispatch({
      type: types.FETCH_PRODUCTS,
      // lang,
      currency,
      perPage: productsPerPage,
      cityId,
      attractionId,
    });

    // Products for the city`attractionId`
    store.dispatch({
      type: types.FETCH_PRODUCTS,
      cityId,
      // lang,
      currency,
      perPage: productsPerPageCity,
      preorder: true,
      separateTo: "cityProducts",
    });

    const city = (
      await Api.get(
        `/api/v2/cities/${cityId}/?${toQueryString({
          lang,
          expand: "h1,meta_title,meta_description,description",
          preorder: true,
        })}`,
        { lang },
      )
    ).data;

    let countryAttractions = {};
    let countryCities = [];
    if (city.country.id) {
      countryAttractions = await Api.get(
        `/api/v2/attractions/?${toQueryString({
          lang,
          country: city.country.id,
          preorder: true,
        })}`,
        {
          lang,
        },
      );

      const { data } = countryAttractions;
      countryAttractions = data;
    }
    const { data } = await Api.get(
      `/api/v2/cities/?${toQueryString({
        country: city.country.id,
        lang,
        preorder: true,
      })}`,
      {
        lang,
      },
    );
    countryCities = data.results;

    const linkings = await fetchLinkings({ lang, id: cityId });

    return {
      countryCities,
      attractionId,
      destinations: overview.topCities,
      countryAttractions,
      city,
      productsPerPage,
      productsPerPageCity,
      currency,
      attraction,
      mobile,
      linkings,
      seoHtml: replaceImgTagsInHtml(seoHtml),
      path: req?.originalUrl,
    };
  } catch (error) {
    // TODO: serve 500 for not 404 errors
    return { statusCode: 404 };
  }
});

const mapStateToProps = ({ products, user, reviews }) => ({
  products,
  cityProducts: products.cityProducts,
  user,
  reviews,
});

export default connect(mapStateToProps)(Attraction);
